import React, { useState } from "react";
import "./Info.css";
import HabitTrackerGame from "../HabitTrackerGame/HabitTrackerGame";

const Info = () => {
  return (
    <section id="info" className="dark-gray">
      <div className="wrapper">
        <h2 style={{ color: "white" }}>Digital Habit Tracker Mini-Game</h2>
        <HabitTrackerGame />
      </div>
    </section>
  );
};

export default Info;