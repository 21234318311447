import React, { useState } from "react";
import "./HabitTrackerGame.css";

const days = ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"];

const HabitTrackerGame = () => {
  const [currentDay, setCurrentDay] = useState(0);
  const [wellnessScore, setWellnessScore] = useState(50);
  const [isGameFinished, setIsGameFinished] = useState(false);

  const [screenTimeSelected, setScreenTimeSelected] = useState(false);
  const [breaksSelected, setBreaksSelected] = useState(false);
  const [offlineActivitiesSelected, setOfflineActivitiesSelected] = useState(false);

  const handleChoice = (category, choice) => {
    let newScore = wellnessScore;

    switch (category) {
      case "screen-time":
        setScreenTimeSelected(true);
        if (choice === "low") newScore += 5;
        else if (choice === "medium") newScore += 0; // Neutral score for 2-5 hours
        else newScore -= 10;
        break;
      case "breaks":
        setBreaksSelected(true);
        if (choice === "frequent") newScore += 10;
        else newScore -= 5;
        break;
      case "offline":
        setOfflineActivitiesSelected(true);
        if (choice === "yes") newScore += 15;
        else newScore -= 10;
        break;
      default:
        break;
    }

    setWellnessScore(newScore);
  };

  const handleNextDay = () => {
    if (currentDay < days.length - 1) {
      setCurrentDay(currentDay + 1);
      setScreenTimeSelected(false);
      setBreaksSelected(false);
      setOfflineActivitiesSelected(false);
    } else {
      setIsGameFinished(true);
    }
  };

  return (
    <div className="habit-tracker-container">
      <h1>Digital Habit Tracker</h1>
      {!isGameFinished ? (
        <>
          <h2>{days[currentDay]}</h2>
          <p>Make your choices for today:</p>

          <h3>Screen Time</h3>
          <button onClick={() => handleChoice("screen-time", "low")} disabled={screenTimeSelected}>
            Less than 2 hours
          </button>
          <button onClick={() => handleChoice("screen-time", "medium")} disabled={screenTimeSelected}>
            2-5 hours (Neutral)
          </button>
          <button onClick={() => handleChoice("screen-time", "high")} disabled={screenTimeSelected}>
            More than 5 hours
          </button>

          <h3>Breaks</h3>
          <button onClick={() => handleChoice("breaks", "frequent")} disabled={breaksSelected}>
            Frequent breaks
          </button>
          <button onClick={() => handleChoice("breaks", "rare")} disabled={breaksSelected}>
            Rarely take breaks
          </button>

          <h3>Offline Activities</h3>
          <button onClick={() => handleChoice("offline", "yes")} disabled={offlineActivitiesSelected}>
            Engaged in offline activities
          </button>
          <button onClick={() => handleChoice("offline", "no")} disabled={offlineActivitiesSelected}>
            No offline activities
          </button>

          <p>Your current wellness score: {wellnessScore}</p>

          <button
            onClick={handleNextDay}
            disabled={!screenTimeSelected || !breaksSelected || !offlineActivitiesSelected}
          >
            Next Day
          </button>
        </>
      ) : (
        <div className="result">
          <h2>Week Complete!</h2>
          <p>Your final wellness score is: {wellnessScore}</p>
          {wellnessScore >= 60 ? (
            <p>Great job! You're balancing your digital habits well!</p>
          ) : (
            <p>Try reducing screen time and taking more breaks for better balance.</p>
          )}
          <button onClick={() => window.location.reload()}>Play Again</button>
        </div>
      )}
    </div>
  );
};

export default HabitTrackerGame;
