import React from 'react';
import "../styles/LoginForm.css";
import { FaUser, FaLock } from 'react-icons/fa';

const LogInPage = () => {
    return (
        <div className="wrapper">
            <form action="">
                <h1>Login</h1>
                <div className="input-box">
                    <input type="text" placeholder="Username" required />
                    <FaUser className='icon' />
                </div>
                <div className="input-box">
                    <input type="password" placeholder="Password" required />
                    <FaLock className='icon' />
                </div>
                    <button type="submit" href="Home">Login</button>
                    <div className='register-link'>
                        <p>Don't have an account? <a href="Register">Register</a></p>
                    </div>
                    
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
            </form>
        </div>
    );
}

export default LogInPage;