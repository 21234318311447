import React from 'react';

const ContactPage = () => {
    return (
        <div>
            <h1 style={{ color: '#fb8122' }}>Contact Page</h1>
            <p>Phone Numbers:</p>
            <ul>
                <li>Sebastian Dumitru: 0725-395-104</li>
                <li>Platon Luca: 0746-264-426</li>
            </ul>
            <p>Email:</p>
            <ul>
                <li>Sebastian Dumitru: dumitrusebastian149@gmail.com</li>
                <li>Platon Luca: lucaxbox2008@gmail.com</li>
            </ul>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    );
};

export default ContactPage;