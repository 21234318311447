/* App.js */
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import HomePage from './pagini/HomePage';
import AboutPage from './pagini/AboutPage';
import ContactPage from './pagini/ContactPage';
import ContactUsPage from './pagini/ContactUsPage';
import LogInPage from './pagini/LogInPage';
import RegisterPage from './pagini/RegisterPage';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
        <nav className="nav-button">
            <Link className="Login" to="/Login">Log In</Link>
            <Link className="Login" to="/Register">Register</Link>
          </nav>
          <nav className="nav-buttons">
            <Link className="button" to="/">Home</Link>
            <Link className="button" to="/about">About</Link>
            <Link className="button" to="/contact">Contact</Link>
          </nav>
        </header>

        {/* Main content goes here */}
        <main>
          <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/login" element={<LogInPage />} />
          <Route path="/register" element={<RegisterPage />} />
          </Routes>
        </main>

        <footer className="App-footer">
          <Link className="button" to="/contact-us">Contact Us</Link>
        </footer>
      </div>
    </Router>
  );
}

export default App;
