import React, { useState } from 'react';
import './Game.css'; // Import the new, stylish CSS

function Game() {
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState({
    screenTime: '',
    notifications: '',
    breaks: '',
    offlineActivities: '',
    screenLimitApps: '',
    bedtimeRoutine: '',
    socialMediaUsage: '',
  });
  const [routine, setRoutine] = useState('');
  const [isQuizFinished, setIsQuizFinished] = useState(false); // New state to check if quiz is finished

  // Questions for each step
  const questions = [
    {
      id: 1,
      question: "How many hours do you spend on screens daily?",
      options: ["Less than 2 hours", "2-5 hours", "5-8 hours", "More than 8 hours"],
      field: "screenTime",
    },
    {
      id: 2,
      question: "Do you feel overwhelmed by notifications?",
      options: ["Yes", "No"],
      field: "notifications",
    },
    {
      id: 3,
      question: "How often do you take breaks from digital devices?",
      options: ["Every hour", "Every few hours", "Rarely"],
      field: "breaks",
    },
    {
      id: 4,
      question: "Do you engage in offline activities like exercise or reading?",
      options: ["Yes", "No"],
      field: "offlineActivities",
    },
    {
      id: 5,
      question: "Do you use apps or settings to limit screen time?",
      options: ["Yes", "No"],
      field: "screenLimitApps",
    },
    {
      id: 6,
      question: "Do you have a consistent bedtime routine without screens?",
      options: ["Yes", "No"],
      field: "bedtimeRoutine",
    },
    {
      id: 7,
      question: "How often do you check social media during the day?",
      options: ["Rarely", "Occasionally", "Frequently"],
      field: "socialMediaUsage",
    }
  ];

  // Move to the next question or finish the quiz
  const nextStep = () => {
    if (step < questions.length) {
      setStep(step + 1); // Move to the next question
    } else {
      suggestRoutine(); // Generate the routine after the last question
      setIsQuizFinished(true); // Mark quiz as finished
    }
  };

  // Handle change in answers
  const handleChange = (option, field) => {
    setAnswers({ ...answers, [field]: option });
  };

  // Function to calculate the wellness routine
  const suggestRoutine = () => {
    let routineSuggestion = "Your personalized Digital Wellness Routine:\n\n";

    if (answers.screenTime === "More than 8 hours") {
      routineSuggestion += "- Consider reducing screen time to under 5 hours per day.\n";
    } else if (answers.screenTime === "5-8 hours") {
      routineSuggestion += "- Try limiting your screen time to around 5 hours.\n";
    }

    if (answers.notifications === "Yes") {
      routineSuggestion += "- Silence notifications or use Do Not Disturb to reduce distractions.\n";
    }

    if (answers.breaks === "Rarely") {
      routineSuggestion += "- Make sure to take regular breaks, at least once every hour.\n";
    }

    if (answers.offlineActivities === "No") {
      routineSuggestion += "- Engage in offline activities like exercise or reading to balance screen time.\n";
    }

    if (answers.screenLimitApps === "No") {
      routineSuggestion += "- Use apps or built-in features to help limit screen time.\n";
    }

    if (answers.bedtimeRoutine === "No") {
      routineSuggestion += "- Establish a screen-free bedtime routine to improve sleep quality.\n";
    }

    if (answers.socialMediaUsage === "Frequently") {
      routineSuggestion += "- Limit your social media usage to designated times during the day.\n";
    }

    setRoutine(routineSuggestion); // Set the generated routine
  };

  return (
    <div className="game-container">
      <h1>Digital Wellness Quiz</h1>

      {/* Show questions one by one */}
      {!isQuizFinished ? (
        <div className="quiz-step">
          <h2>{questions[step - 1].question}</h2>
          <div className="options-container">
            {questions[step - 1].options.map((option, index) => (
              <button
                key={index}
                className={`option-button ${answers[questions[step - 1].field] === option ? 'selected' : ''}`}
                onClick={() => handleChange(option, questions[step - 1].field)}
              >
                {option}
              </button>
            ))}
          </div>

          <button
            className="next-button"
            onClick={nextStep}
            disabled={!answers[questions[step - 1].field]} // Disable if no answer is selected
          >
            {step === questions.length ? "See Results" : "Next"} {/* Button text changes on the last question */}
          </button>

          {/* Progress indicator */}
          <div className="progress-bar">
            <div className="progress" style={{ width: `${(step / questions.length) * 100}%` }}></div>
          </div>
        </div>
      ) : (
        <div className="result">
          <h2>Here is your Digital Wellness Routine</h2>
          <pre>{routine}</pre> {/* Display the result */}
          <button className="restart-button" onClick={() => window.location.reload()}>Restart Quiz</button>
        </div>
      )}
    </div>
  );
}

export default Game;
