export const countries = [
    {
        image: "/images/Image 1.png",
       //title: "Digital Wellbeing Concept 1",
    },
    {
        image: "/images/Image 2.png",
        //title: "Digital Wellbeing Concept 2",
    },
    {
        image: "/images/Image 3.png",
        //title: "Digital Wellbeing Concept 3",
    },
    {
        image: "/images/Image 4.png",
        //title: "Digital Wellbeing Concept 4",
    },
    {
        image: "/images/Image 5.png",
        //title: "Digital Wellbeing Concept 5",
    },
];
