import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "./Caroussel.css";

function Caroussel({ images }) {
    const [current, setCurrent] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    const timeOutRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (autoPlay) {
            timeOutRef.current = setTimeout(() => {
                slideRight();
            }, 2500);
        }

        return () => {
            if (timeOutRef.current) {
                clearTimeout(timeOutRef.current);
            }
        };
    }, [current, autoPlay]);

    const slideRight = () => {
        setCurrent(current === images.length - 1 ? 0 : current + 1);
    };

    const slideLeft = () => {
        setCurrent(current === 0 ? images.length - 1 : current - 1);
    };

    if (location.pathname !== "/") {
        return null; // Render nothing if not on the HomePage
    }

    return (
        <div
            className="caroussel"
            onMouseEnter={() => {
                setAutoPlay(false);
                clearTimeout(timeOutRef.current);
            }}
            onMouseLeave={() => {
                setAutoPlay(true);
            }}
        >
            <div className="caroussel_wrapper" style={{ transform: `translateX(-${current * 100}%)`, width: `${images.length * 100}%` }}>
                {images.map((image, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                index === current
                                    ? "caroussel_card caroussel_card-active"
                                    : "caroussel_card"
                            }
                            style={{ width: `${100 / images.length}%` }}
                        >
                            <img className="card_image" src={image.image} alt={image.title} />
                            <h2>{image.title}</h2>
                            <div className="card_overlay">
                                <h2 className="card_title">{image.title}</h2>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="caroussel_arrow_left" onClick={slideLeft}>
                &lsaquo;
            </div>
            <div className="caroussel_arrow_right" onClick={slideRight}>
                &rsaquo;
            </div>
            <div className="caroussel_pagination">
                {images.map((_, index) => {
                    return (
                        <div
                            key={index}
                            className={
                                index === current
                                    ? "pagination_dot pagination_dot-active"
                                    : "pagination_dot"
                            }
                            onClick={() => setCurrent(index)}
                        ></div>
                    );
                })}
            </div>
        </div>
    );
}

export default Caroussel;