import React from 'react';
import './AboutPage.css';
import Footer from '../Components/Footer/Footer';
const AboutPage = () => {
    return (
        <div>
            <h1>About Page</h1>
            <h2>Our Mission</h2>
            <pre>At Home of Digital Wellness, our mission is to help individuals regain balance in <br/>
            their digital lives. We believe that while technology enriches our lives in<br/>
            countless ways, it's important to manage our digital habits to maintain both<br/>
            mental and physical well-being. Through a combination of resources, tools, and<br/>
            community support, we empower our users to cultivate a healthy relationship with<br/>
            technology and lead more mindful lives.</pre>
            <h2>Why Digital Wellbeing Matters</h2>
            <pre>In today's digital age, it's easy to feel overwhelmed by the constant stream of<br/>
            notifications, social media updates, and endless screen time. Studies show that<br/>
            excessive technology use can lead to stress, anxiety, sleep disorders, and even<br/>
            reduced productivity. Digital wellbeing isn't about giving up technology—it's<br/>
            about using it more intentionally.<br/>
            At Home of Digital Wellness, we offer strategies and solutions that help users find balance,<br/>
            so they can enjoy the benefits of technology without the negative side effects.</pre>
            <h2>What We Offer</h2>
            <pre>We provide a variety of tools and resources to support your digital wellbeing<br/>
            journey, including:<br/>
            <h3>Digital Wellness Dashboard:</h3> Track your screen time, app usage, and digital habits to better understand your<br/>
            relationship with technology.<br/>
            <h3>Mindful Practices:</h3> Daily activities, challenges, and reminders that encourage breaks, meditation,<br/> and time spent away from screens.<br/>
            <h3>Community Support:</h3>A thriving online community where users can share tips, experiences, and success<br/> 
            stories about their journey to digital wellbeing.<br/>
            <h3>Educational Resources:</h3> Articles, blogs, and expert insights into the effects of technology on mental health and<br/>
            practical ways to improve digital habits.</pre>
            <h2>Our Vision</h2>
            <pre>Our vision is to foster a global community that embraces digital mindfulness. We envision<br/>
            a world where people can leverage technology to improve their lives, while still making time<br/>
            for the things that matter most—whether it’s spending time with family, getting outdoors, or<br/>
            simply disconnecting for a while.</pre>
            <h2>Join Us</h2>
            <pre>We're here to help you take control of your digital life. Whether you're looking for a quick<br/>
            reset or a comprehensive plan to improve your digital habits, Home of Digital Wellness has the tools,<br/>
            resources, and community to support you on your journey. Join us in creating a healthier, more<br/>
            mindful approach to technology.</pre>
            <pre>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </pre>
        </div>
    );
};

export default AboutPage;