import React from 'react';
import './Intro.css'; // Import the corresponding CSS file

const Intro = () => {
  return (
    <header className="intro">
      <div className="intro-content">
        <h1 className="intro-title">Welcome to Digital Wellness</h1>
        <p className="intro-subtitle">Find balance in your digital life</p>
      </div>
    </header>
  );
};

export default Intro;
