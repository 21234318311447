import React from 'react';
import Caroussel from '../Components/Caroussel/Caroussel';
import { countries } from '../Components/Caroussel/Data';
import Intro from '../Components/Intro/Intro';
import Topics from '../Components/Topics/Topics';
import Info from '../Components/Info/Info';
import Footer from '../Components/Footer/Footer';
import Game from '../Components/Game/Game'; // Importing Game component

function HomePage() {
    return (
        <div classname="page-container">
            <Intro/>
            <Topics/>
            <Caroussel images={countries} />
            <Game/>
            <Info/>
        </div>
    );
}

export default HomePage;