import React from "react";
import "./Topics.css";
import { bgComponent1 } from "../../assets";
import { bgComponent2 } from "../../assets";

const Topics = () => {
  return (
    <section id="topics">
      <div className="wrapper">
      <div className="topics-container">
      <div className="bg-top-left">
        <bgComponent1/>
      </div>
      <div className="bg-bottom-right">
        <bgComponent2/>
      </div>
        <h2 style={{ color: 'white' }}>What will you learn?</h2>
        <ul className="topics-list">
          <li style={{ color: 'white' }}>Understanding Digital Wellness</li>
          <p style={{ color: 'white' }}>Being aware of how, when, and why you use digital devices can make a huge difference in your life.
          Mindful technology use helps you recognize when your digital habits are harming your well-being
          and empowers you to make conscious decisions, such as setting time limits or scheduling device-free
          moments during the day.</p>
          <li style={{ color: 'white' }}>How to Balance Screen Time</li>
          <p style={{ color: 'white' }}>Finding balance in your digital life is key to maintaining your well-being. Set screen time limits,
          take regular breaks, and create device-free zones to ensure you're using technology mindfully.
          Engage in offline activities and manage notifications to avoid burnout, while staying connected
          in a healthy way. Remember, a balanced approach to screen time leads to better focus, improved
          relationships, and enhanced overall wellness.</p>
          <li style={{ color: 'white' }}>Healthy Digital Habits</li>
          <p style={{ color: 'white' }}>Healthy digital habits are essential for a balanced and fulfilling life. Practice mindful tech use by setting boundaries, such as device-free times or zones, and limiting distractions by managing notifications. Take regular breaks to reduce eye strain and maintain productivity, while also making time for offline activities that recharge you. By cultivating these habits, you can enjoy the benefits of technology without compromising your well-being./</p>
          <li style={{ color: 'white' }}>Mindful Technology Use</li>
          <p style={{ color: 'white' }}>Mindful technology use means being intentional with how, when, and why you interact with digital devices. Focus on activities that enrich your life and limit distractions like mindless scrolling or excessive multitasking. Set specific times for checking emails or social media, and take breaks to stay present in the moment. By practicing mindfulness with your tech, you can enhance your well-being and make more meaningful connections both online and offline.</p>
        </ul>
      </div>
      </div>
    </section>
  );
};

export default Topics;
